.ta-chat-init-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .ta-chat-button {
    width: 57px;
    height: 57px;
    min-width: 57px;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 90px;
    //background: #4285F4 url("./../assets/ta/button-icon.svg") no-repeat center;
    cursor: pointer;
    margin-top: 10px;
    box-shadow: 2px 2px 8px rgba(0,0,0,.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ta-chat-button.close {
    //background: #4285F4 url("./../assets/ta/button-icon-close.svg") no-repeat center;
  }
  
}

.ta-chat-init-button.ta-welcome-hidden{
  flex-direction: column;
  align-items: flex-end;
}

.ta-chat-init-button.ta-small-device {
  display: none;
}
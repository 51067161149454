.ta-chat-init-button {
  @media screen and (max-width: 400px) and (max-width : 480px) {
    .ta-bubble-container > .ta-box > .logo {
      float: none !important;
    }

    .ta-bubble-container > .ta-box > .message{
      margin-left: 0px !important;
    }

    .ta-bubble-container {
      width: 202px !important;
    }

    .left {
      left: -285px!important;
      top: -60px!important;
      width: 260px!important;
      height: 108px!important;
    }

    .top {
      left: -155px !important;
      top: -165px!important;
    }

  }

  // @media screen and (min-width : 320px) and (max-width : 480px) {
  //   .left {
  //     left: -270px;
  //     top: -60px;
  //     width: 249px;
  //     height: 115px;
  //   }
  // }

  @keyframes ta-popup {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  .ta-bubble-container {
    position: absolute;
    width: 235px;
    background: #ffffff;
    color: #1F1F1F;
    font-family: Arial;
    font-size: 14px;
    box-shadow: 0px 5px 15px -1px #a3a3a3;
    text-align: center;
    border-radius: 11px;
    z-index: 1000;
    animation: ta-popup 0.5s;
    cursor: pointer;
  }

  .ta-bubble-container:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
  }

  .top {
    @extend .ta-bubble-container;
    left: -185px;
    top: -110px;
  }

  .top:after{
    border-color: #ffffff transparent;
    border-width: 12px 12px 0;
    bottom: -12px;
    left: 90%;
    margin-left: -12px;
  }

  .left {
    @extend .ta-bubble-container;
    left: -335px;
    top: -60px;
    width: 312px;
    height: 92px;
  }

  .left:after {
    border-width: 20px 0 0 20px;
    border-color: transparent transparent transparent #ffffff;
    top: 77%;
    right: -20px;
    margin-top: -10px;
  }

  .ta-bubble-container > .close-btn {
    position: relative;
  }

  .ta-bubble-container > .close-btn > svg {
    position: absolute;
    top: 10px;
    right: 10px;
    filter: invert(44%) sepia(91%) saturate(2650%) hue-rotate(203deg) brightness(100%) contrast(87%);
  }

  .ta-bubble-container > .ta-box {
    // padding: 25px;
    display: flex;
    flex-direction: row;
  }

  .ta-bubble-container > .ta-box > .logo {
    float: left;
    position: relative;
    top: 0;
    left: 0;
    margin: 20px 20px 20px 20px;

    .img1 {
      position: relative;
      top: 0;
      left: 0;
    }

    .img2 {
      position: absolute;
      top: 30px;
      left: 30px;
      /* border: 1px green solid; */
      width: 25px;
      height: 25px;
      background-color: white !important;
    }
  }

  .ta-bubble-container > .ta-box > .logo > img{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    max-width: 50px;
    max-height: 50px;
  }

  .ta-bubble-container > .ta-box > .message{
    // margin: 25px 0px;
    text-align: left;
    font-size: 17px;
    margin: 24px 20px 20px 0px;
    overflow-wrap: anywhere;
  }

  .ta-bubble-container.hidden {
    display: none;
  }
}

.ta-chat-form {
  text-align: center;
  width: 300px;
  margin: 0 auto;
  .ta-form-control {
    margin-bottom: 10px;
    width: 100%;

    input[type="text"] {
      padding: 0;
    };

    input[type="tel"] {
      padding: 0;
    }

    input {
      height: 40px;
      width: 300px;
      border-radius: 5px;
      background: #F2F2F2;
      border: none;
      //padding: 12px;
      text-indent: 12px;
    }

    @media screen and (max-width: 415px) and (max-height: 415px) {
      input {
        width: 100%;
      }
    }

    input::placeholder {
      //padding-top: 12px;
      //padding-bottom: 12px;
    }


    input:focus {
      border: 1px solid #DADFDF;
      border-radius: 5px;
      outline: none;
    }


    textarea {
      height: 83px;
      width: 300px;
      background: #F2F2F2;
      border-radius: 5px;
      resize: none;
      border: none;
      text-indent: 12px;
      padding-top: 10px;
    }

    @media screen and (max-width: 415px) and (max-height: 415px) {
      textarea {
        width: 100%;
        padding: 0;
      }
    }

    textarea::placeholder {
      //padding-bottom: 12px;
    }

    textarea:focus {
      border-radius: 5px;

      border: 1px solid #DADFDF;
      box-sizing: border-box;
      outline: none;
    }
  }

  .ta-form-control.ta-form-control-error {
    border-color: red;
    border-style: solid;
    border-width: 1px;
  }

  .ta-chat-label {
    color:  #C1CACA;
    font-family: Roboto, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0;
  }

  .ta-chat-submit {
    width: 100%;
    background-color: #4285F4;
    font-size: 14px;
    color: white;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-top: 25px;
  }

  .ta-chat-submit:disabled {
    background-color: #00e68a;
  }

  .ta-chat-char-counter {
    font-size: 10px;
    color: #889191;
    position: relative;
    top: -20px;
    text-align: right;
    padding-right: 10px;
  }
}

@media screen and (max-width: 340px) {
  .ta-chat-form {
    width: 100%;
    margin: 0;
  }
}

.ta-widget-chat *{
  outline: none;
  box-sizing: border-box;
}

.ta-widget-chat{
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: flex-end;
  bottom: 100px;
  right: 30px;
  max-width: 340px;
  width: 100%;
  max-height: 545px;
  height: 80vh;
  background: #F9F9F9;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: 'Roboto';
}
@media (max-width:768px){
  .ta-widget-chat{
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-height: unset;
    max-width: unset;
    border-radius: 0;
  }
}
.ta-widget-chat-header{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 69px;
  min-height: 69px;
  background: #4285F4;
  border-radius: 10px 10px 0 0;
}
@media (max-width:768px){
  .ta-widget-chat-header{
    border-radius: 0;
  }
}
.ta-widget-chat-header-title{
  margin-left: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}
.ta-widget-chat-close{
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition-duration: 0.3s;
}
.ta-widget-chat-close:hover{
  opacity: 0.7;
}
.ta-widget-chat-powered{
  display: flex;
  height: 52px;
  min-height: 52px;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 11px;
  line-height: 107.6%;
  text-align: center;
  color: #B5BBBB;

}
.ta-widget-chat-powered a{
  color: #1F1F1F;
  text-decoration: none;
}
.ta-widget-chat-powered a span{
  color: #4285F4;
  text-decoration: none;
}
.ta-widget-chat-powered a:hover{
  text-decoration: underline;
}
.ta-widget-chat-form{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px 20px 0 20px;
  overflow-y: auto;
  padding-bottom: 0 !important;
  margin-right: 2px;
  scrollbar-width: thin;
}
.ta-widget-chat-form::-webkit-scrollbar {
  width: 4px;
}
.ta-widget-chat-form::-webkit-scrollbar-track {
  background-color: #DDDDDD;
  border-radius: 10px;
}
.ta-widget-chat-form::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}
.ta-widget-chat-form-info{
  display: flex;
  margin-right: 20px;
  background: #E7EFEF;
  border-radius: 0 10px 10px 10px;
  padding: 8px 20px;
  font-size: 14px;
  line-height: 20px;
  color:#406DB9;
}
.ta-widget-chat-form-fon-inputs{
  display: flex;
  flex-direction: column;
  padding: 15px 20px 9px 20px;
  background-color: #FFFFFF;
  border-radius: 10px 0 10px 10px;
  margin-top: 19px;
  margin-left: 40px;
}
.ta-widget-chat-form-wrap-input{
  display: flex;
  position: relative;
  min-height: 40px;
  padding-top: 12px;
  width: 100%;
  margin-bottom: 16px;
}
.ta-widget-chat-form-wrap-input input{
  border: none;
  min-height: 28px;
  height: 28px;
  box-shadow: none !important;
  font-family: 'Roboto';
  width: 100%;
}
.ta-widget-chat-form-wrap-input .country-list {
  width: 240px;
  z-index: 100;
}

.ta-widget-chat-form-wrap-input .country-list::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.ta-widget-chat-form-wrap-input .country-list::-webkit-scrollbar{
	width: 12px;
	background-color: #F5F5F5;
}

.ta-widget-chat-form-wrap-input .country-list::-webkit-scrollbar-thumb{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgb(156, 156, 156);
}

.ta-widget-chat-form-wrap-input textarea{
  border: none;
  min-height: 28px;
  height: 28px;
  resize: none;
  width: 100%;
  padding-top: 4px;
  overflow-y: auto;
  padding-bottom: 0 !important;
  margin-right: 2px;
  scrollbar-width: thin;
  font-family: 'Roboto';
}
.ta-widget-chat-form-wrap-input input:invalid{
    box-shadow:none;
}
.ta-widget-chat-form-wrap-input textarea::-webkit-scrollbar {
  width: 0px;
}
.ta-widget-chat-form-wrap-input textarea::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
.ta-widget-chat-form-wrap-input textarea::-webkit-scrollbar-thumb {
  background:transparent;
  border-radius: 10px;
}








.ta-widget-chat-form-wrap-input input:focus,
.ta-widget-chat-form-wrap-input input:active{
  border: none;
}
.ta-widget-chat-form-wrap-input label{
  display: flex;
  position: absolute;
  top: 18px;
  left: 0;
  font-size: 14px;
  line-height: 16px;
  color: #A6ACAC;
  transition-duration: 0.3s;
}
.ta-widget-chat-form-wrap-input  .input-bar{
  background-color: #DADFDF;
  bottom: 0;
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 10;
}
.ta-widget-chat-form-wrap-input input:focus~.input-bar:after,
.ta-widget-chat-form-wrap-input input:focus~.input-bar:before,
.ta-widget-chat-form-wrap-input textarea:focus~.input-bar:after,
.ta-widget-chat-form-wrap-input textarea:focus~.input-bar:before {
  width: 50%;
}
.input-bar:before,
.input-bar:after {
  background-color: #4285F4;
}
.input-bar:before {
  left: 50%;
}
.input-bar:after{
  right: 50%;
}
.input-bar:after,
.input-bar:before {
  content: "";
  height: 1px;
  position: absolute;
  -webkit-transition: .2s ease;
  transition: .2s ease;
  width: 0;
}
.ta-widget-chat-form-wrap-input input:focus~label,
.ta-widget-chat-form-wrap-input input:valid~label,
.ta-widget-chat-form-wrap-input textarea:focus~label,
.ta-widget-chat-form-wrap-input textarea:valid~label {
  top: 0px;
  color: #29487D;
  font-weight: 500;
  font-size: 10px;
}
.ta-widget-chat-form-about{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: flex-end;
  margin: 16px 0;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: #889191;
  flex-grow: 1;
}
.ta-widget-chat-form-send{
  display: flex;
  width: 100%;
  height: 40px;
  min-height: 40px;
  border: none;
  background: #4285F4;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  justify-content: center;
  transition-duration: 0.3s;
  cursor: pointer;

}
.ta-widget-chat-form-send:hover{
  background-color: #29487D;
}
.ta-widget-chat-form-send:disabled{
  background: #A6ACAC !important;
  color: #ffffff !important;
  cursor: default;
}
.ta-widget-chat-form-wrap-input-check{
  position: absolute;
  right: 0;
  top: 20px;
}
.ta-widget-chat-form-delivered{
  display: flex;
  position: relative;
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px 0 10px 10px;
  background-color: #4285F4;
  margin-left: 40px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  margin-top: 20px;
  word-break: break-word;
}
.ta-widget-chat-form-delivered-info{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: -17px;
  font-weight: 500;
  font-size: 10px;
  line-height: 107.6%;
  text-align: right;
  text-transform: uppercase;
  color: #8F9797;
}
.ta-widget-chat-form-delivered-info svg{
  margin: 0 4px 0 10px;
}
.ta-widget-chat-form-received{
  display: flex;
  flex-direction: column;
  background: #E7EFEF;
  border-radius: 0 10px 10px 10px;
  padding: 17px 10px 10px 10px;
  margin-right: 20px;
}
.ta-widget-chat-form-received-phone{
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #29487D;
  margin-left: 7px;
}
.ta-widget-chat-form-received-body{
  display: flex;
  position: relative;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 15px;
  margin-top: 16px;
  color: #8F9797;
  font-size: 14px;
  line-height: 20px;
}
.ta-widget-chat-form-received-title{
  font-weight: 700;
  color: #1f1f1f;
}
.ta-widget-chat-form-received-body svg{
  position: absolute;
  top: 10px;
  right: 10px;
}

.ta-animate {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.ta-animate-fast{
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

.ta-fade-in {
  animation-name: fadeInBottom;
}

@keyframes fadeInBottom {
  from {
      opacity: 0;
      transform: translateY(10%);
  }
  to { opacity: 1 }
}

.ta-rotate-in {
  transform: rotate(180deg);
  transition: transform .2s ease-in;
}

.ta-rotate-out {
  transform: rotate(0deg);
  transition: transform .2s ease-out;
}

.d-none {
  display: none;
}